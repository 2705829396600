import React, { useState, useRef } from 'react';
import { Link } from 'gatsby';

import styles from './nav.module.scss';

const Nav = () => {
  const [toggled, setToggled] = useState(false);
  const toggleState = (bool) => setToggled(bool === undefined ? !toggled : bool);
  const navEl = useRef(null);
  const hamburgerBtnEl = useRef(null);
  const dropdownUlEl = useRef(null);

  const handleListener = (event) => {
    const { target } = event;
    if (!navEl.current || !navEl.current.contains(target)) {
      toggleState(false);
      document.documentElement.removeEventListener('click', handleListener);
    } else if (hamburgerBtnEl.current.contains(target)) {
      document.documentElement.removeEventListener('click', handleListener);
    }
  };

  const handleBtnClick = () => {
    if (!toggled) {
      toggleState(true);
      document.documentElement.addEventListener('click', handleListener);
    } else toggleState(false);
  };

  const handlePhotosFocus = ({ target }) => {
    if (target.contains(dropdownUlEl.current)) {
      dropdownUlEl.current.style.visibility = 'visible';
    }
  };

  const handlePhotosBlur = () => {
    // let browser re-focus then check active element
    setTimeout(() => {
      if (dropdownUlEl.current && !dropdownUlEl.current.contains(document.activeElement)) {
        dropdownUlEl.current.style.removeProperty('visibility');
      }
    }, 0);
  };

  const isPhotosURL =
    typeof window !== 'undefined' && window.location.pathname.includes('/photos/');
  return (
    <div className={styles.navWrapper} ref={navEl}>
      <button
        type="button"
        aria-label="Toggle navigation menu"
        aria-expanded={toggled}
        className={styles.button}
        onClick={handleBtnClick}
        ref={hamburgerBtnEl}
      >
        <div />
        <div />
        <div />
      </button>
      <nav className={styles.menu}>
        <Link activeClassName={styles.active} to="/">
          Home
        </Link>
        <Link activeClassName={styles.active} to="/mission">
          Our&nbsp;Mission
        </Link>
        <Link activeClassName={styles.active} to="/services">
          Services
        </Link>
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          role="navigation"
          // eslint-disable-next-line prefer-template
          className={`${styles.dropdown}${isPhotosURL ? ' ' + styles.active : ''}`}
          onFocus={handlePhotosFocus}
          onBlur={handlePhotosBlur}
        >
          Photos
          <ul ref={dropdownUlEl}>
            <li>
              <Link activeClassName={styles.active} to="/photos/Los-Gatos">
                Los Gatos
              </Link>
            </li>
            <li>
              <Link activeClassName={styles.active} to="/photos/Campbell">
                Campbell
              </Link>
            </li>
            <li>
              <Link activeClassName={styles.active} to="/photos/Santa-Teresa">
                Santa Teresa
              </Link>
            </li>
          </ul>
        </div>
        <Link activeClassName={styles.active} to="/contact">
          Contact&nbsp;Us
        </Link>
      </nav>
    </div>
  );
};

export default Nav;
