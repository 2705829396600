import { Link } from 'gatsby';
import React from 'react';
import Logo from './logo';
import Nav from './nav';

import styles from './header.module.scss';

const Header = () => (
  <header className={styles.header}>
    <div className={styles.wrapper}>
      <Link className={styles.logoWrapper} to="/">
        <Logo />
        <div className={styles.logoText}>
          <div className={styles.main}>Mina&apos;s Elderly Care Homes</div>
          <div>Caring comes from the heart</div>
        </div>
      </Link>
      <Nav />
    </div>
  </header>
);

export default Header;
